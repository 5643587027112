// At the top of your index.js
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";

config.autoAddCss = false;

// Components used in Layout
export { default as Preloader } from "./Preloader";
export { default as NavBar } from "./NavBar";
export { default as ReturnToTop } from "./ReturnToTop";
export { default as Footer } from "./Footer";
export {default as Layout} from "./Layout";

//Components used in Home
export { default as Intro } from "./Intro";
export { default as About } from "./About";
export { default as Services } from "./Services";
export {default as Home} from "./Home";

// Individual Pages
export { default as ServicesPage } from "./ServicesPage";
export {default as PrivacyPolicy} from "./PrivacyPolicy";
export {default as Terms} from "./TermsConditions";
export {default as CapabilityStatement} from "./CapabilityStatement";
export {default as CapabilityStatementPDF} from "./CapabilityStatementPDF";
export {default as Founder} from "./Founder";

// Form Pages
export {default as GetStarted} from "./GetStarted";

// ERROR PAGES
export {default as NotFound404} from "./NotFound404";
export { default as ErrorBoundary } from "./ErrorBoundary";