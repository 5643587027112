import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/services.css";

/**
 * Services - Component for displaying the company's featured services and solutions.
 * @returns {React.Component} The rendered component.
 */
const Services = () => {
  // Direct navigation without React Router for service detail pages
  // This ensures full page reload to initialize state correctly
  const navigateToService = (serviceId) => {
    window.location.href = `/services#${serviceId}`;
  };

  return (
    <section id="services" className="section-wrapper section-light">
      <div className="container">
        <h2 className="section-heading">Our Solutions</h2>
        <p className="section-subheading">
          Innovative technology services tailored for modern business needs
        </p>
        
        {/* Featured services */}
        <div className="featured-services">
          <div className="featured-service-card">
            <div className="service-icon-wrapper">
              <FontAwesomeIcon icon={["fas", "robot"]} className="service-icon" aria-hidden="true" />
            </div>
            <h3>AI Solutions</h3>
            <p>Intelligent automation and data analytics that transform operations and enhance decision-making</p>
            <ul className="service-features">
              <li>Custom AI Implementation</li>
              <li>Machine Learning Models</li>
              <li>Natural Language Processing</li>
            </ul>
            <button 
              onClick={() => navigateToService('ai-implementations')} 
              className="service-link"
            >
              Learn more <FontAwesomeIcon icon={["fas", "arrow-right"]} />
            </button>
          </div>
          
          <div className="featured-service-card">
            <div className="service-icon-wrapper">
              <FontAwesomeIcon icon={["fas", "cloud"]} className="service-icon" aria-hidden="true" />
            </div>
            <h3>Cloud Computing</h3>
            <p>Scalable, secure infrastructure services that optimize performance and reduce operational costs</p>
            <ul className="service-features">
              <li>Cloud Migration</li>
              <li>Hybrid Cloud Solutions</li>
              <li>Infrastructure Optimization</li>
            </ul>
            <button 
              onClick={() => navigateToService('cloud-engineering')} 
              className="service-link"
            >
              Learn more <FontAwesomeIcon icon={["fas", "arrow-right"]} />
            </button>
          </div>
          
          <div className="featured-service-card">
            <div className="service-icon-wrapper">
              <FontAwesomeIcon icon={["fas", "mobile-screen"]} className="service-icon" aria-hidden="true" />
            </div>
            <h3>Mobile-First Design</h3>
            <p>Responsive applications and websites that provide exceptional user experiences across all devices</p>
            <ul className="service-features">
              <li>Progressive Web Apps</li>
              <li>Responsive Web Design</li>
              <li>Cross-Platform Development</li>
            </ul>
            <button 
              onClick={() => navigateToService('website-design')} 
              className="service-link"
            >
              Learn more <FontAwesomeIcon icon={["fas", "arrow-right"]} />
            </button>
          </div>
        </div>
        
        <div className="services-cta">
          <Link to="/services" className="view-all-services">View All Services <FontAwesomeIcon icon={["fas", "arrow-right"]} /></Link>
        </div>
      </div>
    </section>
  );
};

export default Services;