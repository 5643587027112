import React from 'react';
import '../styles/capabilitystatementpdf.css';

/**
 * CapabilityStatementPDF - Component for PDF version of capability statement.
 * This is optimized for single-page PDF printing following federal contractor standards.
 * @returns {React.Component} The rendered component.
 */
const CapabilityStatementPDF = React.forwardRef((props, ref) => {
  // Company core information
  const coreInfo = {
    businessName: "SerityOps Innovations, LLC",
    website: "https://serityops.com",
    businessSize: "Micro / Small",
    ueiNumber: "L5PJSLA7PR45",
    cage: "9T3E8",
    founded: "2024",
    certifications: [
      "Service-Disabled Veteran-Owned Small Business (SDVOSB)",
      "Woman-Owned Small Business (WOSB)",
      "Minority-Owned Business Enterprise (MBE)"
    ],
    naicsCodes: [
      { code: "541511", description: "Custom Computer Programming Services (PRIMARY)" },
      { code: "518210", description: "Data Processing, Hosting and Related Services" },
      { code: "541611", description: "Administrative Management and General Management Consulting Services" },
      { code: "541690", description: "Other Scientific and Technical Consulting Services" }
    ],
    contactInfo: {
      name: "Linn Camacho",
      title: "Founder & CEO",
      email: "info@serityops.com"
    }
  };

  // Core capabilities with concise descriptions
  const coreCapabilities = [
    {
      title: "AI Implementations",
      capabilities: [
        "Custom AI solutions for business challenges",
        "Machine learning for predictive analytics",
        "AI integration with existing systems"
      ]
    },
    {
      title: "Web Development",
      capabilities: [
        "User-centered responsive web applications",
        "ADA compliant and accessible design",
        "Secure web application architecture"
      ]
    },
    {
      title: "Cloud Engineering",
      capabilities: [
        "AWS, GCP, and Azure architecture",
        "Secure cloud migration services",
        "Infrastructure as Code (IaC) deployment"
      ]
    },
    {
      title: "Data Analytics",
      capabilities: [
        "Big data architecture design",
        "Business intelligence dashboards",
        "Data visualization for insights"
      ]
    }
  ];

  // Company differentiators - more concise
  const differentiators = [
    {
      title: "Security-First Approach",
      description: "Security integration at every stage ensuring robust protection"
    },
    {
      title: "Rapid Deployment",
      description: "Agile methodology enabling quick implementation with high quality"
    },
    {
      title: "Veteran & Minority Leadership",
      description: "Led by a service-disabled veteran with federal IT expertise"
    },
    {
      title: "Innovation Excellence",
      description: "Cutting-edge technologies delivering innovative solutions"
    }
  ];

  // Past performance - actual references from the site
  const pastPerformances = [
    {
      client: "Personal Celebratory Event",
      project: "Custom Event Website",
      description: "Designed and developed a personalized website for a celebratory event, featuring RSVP functionality, event information, and interactive gallery."
    },
    {
      client: "Destini Delights",
      project: "Catering Order System",
      description: "Created a custom ordering web application for a small catering business, enabling online menu browsing, ordering, and payment processing."
    },
    {
      client: "Global Gaming Community",
      project: "Discord Bot Development",
      description: "Developed a Discord bot with dynamic setup and multilingual support for global gamers, featuring customizable commands."
    }
  ];

  return (
    <div className="cs-pdf-container" ref={ref}>
      {/* Header */}
      <div className="cs-pdf-header">
        <div className="cs-pdf-company-name">
          <h2>{coreInfo.businessName}</h2>
        </div>
        <div className="cs-pdf-title">
          <h1>Capability Statement</h1>
          <p>Modern, Accessible, Future-Ready IT Solutions</p>
        </div>
      </div>

      {/* Company Overview Section */}
      <div className="cs-pdf-overview">
        <p>
          SerityOps Innovations is a service-disabled veteran-owned, woman-owned small business specializing in advanced technology solutions. We deliver secure, scalable, and innovative AI, cloud, and data solutions to help government entities achieve their mission objectives efficiently.
        </p>
      </div>

      {/* Two-column layout for content */}
      <div className="cs-pdf-content">
        {/* Left column */}
        <div className="cs-pdf-column cs-pdf-left">
          {/* Company Profile */}
          <div className="cs-pdf-section">
            <h2>Company Profile</h2>
            <table className="cs-pdf-info-table">
              <tbody>
                <tr>
                  <td><strong>Business Name:</strong></td>
                  <td>{coreInfo.businessName}</td>
                </tr>
                <tr>
                  <td><strong>Business Size:</strong></td>
                  <td>{coreInfo.businessSize}</td>
                </tr>
                <tr>
                  <td><strong>UEI Number:</strong></td>
                  <td>{coreInfo.ueiNumber}</td>
                </tr>
                <tr>
                  <td><strong>CAGE Code:</strong></td>
                  <td>{coreInfo.cage}</td>
                </tr>
                <tr>
                  <td><strong>Founded:</strong></td>
                  <td>{coreInfo.founded}</td>
                </tr>
                <tr>
                  <td><strong>Website:</strong></td>
                  <td>{coreInfo.website}</td>
                </tr>
                <tr>
                  <td><strong>Email:</strong></td>
                  <td>{coreInfo.contactInfo.email}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Certifications */}
          <div className="cs-pdf-section">
            <h2>Certifications</h2>
            <ul className="cs-pdf-cert-list">
              {coreInfo.certifications.map((cert, index) => (
                <li key={index}>{cert}</li>
              ))}
            </ul>
          </div>

          {/* NAICS Codes */}
          <div className="cs-pdf-section">
            <h2>NAICS Codes</h2>
            <table className="cs-pdf-naics-table">
              <tbody>
                {coreInfo.naicsCodes.map((naics, index) => (
                  <tr key={index}>
                    <td className="cs-pdf-naics-code">{naics.code}</td>
                    <td className="cs-pdf-naics-desc">{naics.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Differentiators */}
          <div className="cs-pdf-section">
            <h2>Company Differentiators</h2>
            <div className="cs-pdf-differentiators">
              {differentiators.map((diff, index) => (
                <div key={index} className="cs-pdf-diff-item">
                  <p className="cs-pdf-diff-title"><strong>{diff.title}</strong></p>
                  <p className="cs-pdf-diff-desc">{diff.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Right column */}
        <div className="cs-pdf-column cs-pdf-right">
          {/* Core Capabilities */}
          <div className="cs-pdf-section">
            <h2>Core Capabilities</h2>
            {coreCapabilities.map((cap, index) => (
              <div key={index} className="cs-pdf-capability-item">
                <h3>{cap.title}</h3>
                <ul className="cs-pdf-capability-list">
                  {cap.capabilities.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* Past Performance */}
          <div className="cs-pdf-section">
            <h2>Past Performance</h2>
            <div className="cs-pdf-performance">
              {pastPerformances.map((perf, index) => (
                <div key={index} className="cs-pdf-performance-item">
                  <p className="cs-pdf-perf-client"><strong>{perf.client}</strong> - {perf.project}</p>
                  <p className="cs-pdf-perf-desc">{perf.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="cs-pdf-footer">
        <div className="cs-pdf-footer-content">
          <p>SerityOps Innovations, LLC | CAGE: {coreInfo.cage} | UEI: {coreInfo.ueiNumber}</p>
          <p className="cs-pdf-footer-tag">Service-Disabled Veteran-Owned & Woman-Owned Small Business</p>
        </div>
      </div>
    </div>
  );
});

export default CapabilityStatementPDF; 