import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import companyLogo from "../assets/images/serityops_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faBars, faTimes } from "@awesome.me/kit-e790b06899/icons/classic/solid";
import "../styles/navbar.css";

/**
 * NavBar - The navigation bar component which contains the company logo and navigation menu.
 * @returns {React.Element} the rendered element
 */
const NavBar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();

  // Handle scroll event to add styling when scrolled
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => {
    setIsMenuOpen(false);
    setIsDropdownOpen(false);
  };

  const handleDirectNavigation = (path) => {
    console.log(`Direct navigation to: ${path}`);
    window.location.href = path;
  };

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`} role="navigation">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick={closeMenu}>
          <img src={companyLogo} alt="SerityOps Innovations Logo" />
        </Link>
        
        <button 
          className="navbar-mobile-trigger" 
          onClick={toggleMenu}
          aria-expanded={isMenuOpen}
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        >
          <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} aria-hidden="true" />
        </button>
        
        <div className={`navbar-menu ${isMenuOpen ? 'active' : ''}`}>
          <div className="navbar-links">
            <Link to="/" className="nav-link" onClick={closeMenu}>Home</Link>
            
            <div className="dropdown">
              <button 
                className="nav-link dropdown-toggle" 
                onClick={toggleDropdown} 
                aria-expanded={isDropdownOpen}
                aria-haspopup="true"
                aria-controls="services-dropdown-menu"
              >
                Services 
                <FontAwesomeIcon 
                  icon={isDropdownOpen ? faMinus : faPlus} 
                  className="dropdown-icon" 
                  aria-hidden="true" 
                />
              </button>
              <div 
                id="services-dropdown-menu"
                className={`dropdown-menu ${isDropdownOpen ? 'active' : ''}`}
                role="menu" 
                aria-label="Services menu"
              >
                <Link to="/services" className="dropdown-item" onClick={closeMenu} role="menuitem">All Services</Link>
                <div className="dropdown-divider"></div>
                <span className="dropdown-header" id="core-services-header">Core Services</span>
                <Link to="/services#ai-implementations" className="dropdown-item" onClick={closeMenu} role="menuitem" aria-labelledby="core-services-header">AI Implementations</Link>
                <Link to="/services#website-design" className="dropdown-item" onClick={closeMenu} role="menuitem" aria-labelledby="core-services-header">Website Design</Link>
                <Link to="/services#cloud-engineering" className="dropdown-item" onClick={closeMenu} role="menuitem" aria-labelledby="core-services-header">Cloud Engineering</Link>
                <Link to="/services#data-analytics" className="dropdown-item" onClick={closeMenu} role="menuitem" aria-labelledby="core-services-header">Data Analytics & Engineering</Link>
                <Link to="/services#edutech" className="dropdown-item" onClick={closeMenu} role="menuitem">Education Technology</Link>
              </div>
            </div>
            
            <Link to="/founder" className="nav-link" onClick={closeMenu}>About</Link>
            
            <button 
              className="nav-link" 
              style={{ background: 'none', border: 'none', cursor: 'pointer', display: 'inline-block', fontFamily: 'inherit', fontSize: 'inherit' }}
              onClick={() => handleDirectNavigation('/get-started')}
            >
              Contact
            </button>
            
            <Link to="/capability-statement" className="nav-link" onClick={closeMenu}>Capability Statement</Link>
          </div>
          
          <Link to="/get-started" className="navbar-cta-button" onClick={closeMenu}>
            Get Started
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;