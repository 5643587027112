import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "../styles/about.css";

/**
 * About - Component for the About Us section including company mission and values.
 * @returns {React.Component} The rendered component.
 */
const About = () => {
  return (
    <section id="about" className="section-wrapper section-light">
      <div className="container">
        <div className="about-section">
          <div className="section-content">
            <h2 className="section-heading">About SerityOps Innovations, LLC</h2>
            <p className="section-subheading">
              Service-Disabled Veteran Owned Small Business committed to delivering excellent IT solutions.
            </p>
            
            <div className="credential-badge">
              <div className="badge-icon">
                <FontAwesomeIcon icon={["fas", "medal"]} aria-hidden="true" />
              </div>
              <div className="badge-text">
                <h3>Service-Disabled Veteran Owned</h3>
                <p>Proud to serve our clients with the same dedication we served our country.</p>
              </div>
            </div>

            <div className="about-grid">
              <div className="highlight">
                <h3>Our Mission</h3>
                <p>
                  Our mission is to bolster our clients' operational efficiency and strategic 
                  decision-making through advanced digital transformation solutions. SerityOps Innovations, LLC 
                  exists to provide modern, accessible, and future-ready IT solutions that empower 
                  our clients to thrive in an increasingly digital world.
                </p>
              </div>
              <div className="highlight">
                <h3>Our Approach</h3>
                <p>
                  We combine technical excellence with a human-centered
                  approach. Every solution we build focuses on real people with
                  real needs, ensuring technology enhances rather than
                  complicates. We believe technology should work for you, not the other way around.
                </p>
              </div>
            </div>
            
            <div className="founder-cta">
              <h3>Meet Our Founder</h3>
              <p>
                Learn about Linn Camacho, our founder and CEO, and her vision for 
                making technology accessible and impactful for veterans and underrepresented communities.
              </p>
              <Link to="/founder" className="founder-link">
                <span>Meet Our Founder</span>
                <FontAwesomeIcon icon={["fas", "arrow-right"]} />
              </Link>
            </div>

            <h3 className="subsection-heading">Who We Serve</h3>
            <div className="audience-grid">
              <div className="audience-card">
                <h4>Individual Consumers</h4>
                <p>
                  We offer personalized AI assistants, e-commerce solutions, and
                  web design services tailored to help individuals leverage
                  technology in their daily lives and personal projects.
                </p>
              </div>
              <div className="audience-card">
                <h4>Small Businesses</h4>
                <p>
                  Our business automation tools, cloud migration services, and
                  progressive web apps help small businesses compete effectively
                  in today's market without enterprise-level budgets.
                </p>
              </div>
              <div className="audience-card">
                <h4>Government Agencies</h4>
                <p>
                  We provide DevSecOps expertise, Infrastructure-as-Code
                  solutions, and compliance-ready delivery to help government
                  agencies serve citizens more effectively through technology.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;