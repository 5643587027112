import React from "react";
import { Intro, About, Services } from './';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Home - The Home page component. It includes the Intro, About, and ServicesDetails components.
 * With improved visual separation between sections and enhanced featured section.
 * @returns {React.Component} The rendered component.
 */
const Home = () => (
  <div className="Home">
    <div className="section-wrapper section-primary" style={{paddingBottom: 0, marginBottom: -1}}>
      <Intro />
    </div>
    
    <div className="section-wrapper section-light" style={{paddingTop: 0, marginTop: 0}}>
      <About />
    </div>
    
    <div className="section-wrapper section-accent">
      <Services />
    </div>
    
    <div className="section-wrapper section-dark">
      <div className="container">
        <div className="featured-section">
          <h2 className="section-heading">Future-Ready Technology Solutions</h2>
          <p className="section-description">
            Empowering businesses, agencies, and consumers with innovative digital services
          </p>
          
          <div className="service-cards-grid">
            <div className="featured-card">
              <div className="featured-icon">
                <FontAwesomeIcon icon={["fas", "lightbulb"]} />
              </div>
              <h3>Innovative Approach</h3>
              <p>Cutting-edge solutions tailored to your unique business challenges</p>
            </div>
            
            <div className="featured-card">
              <div className="featured-icon">
                <FontAwesomeIcon icon={["fas", "chart-line"]} />
              </div>
              <h3>Growth-Focused</h3>
              <p>Technology solutions that drive business expansion and efficiency</p>
            </div>
            
            <div className="featured-card">
              <div className="featured-icon">
                <FontAwesomeIcon icon={["fas", "lock"]} />
              </div>
              <h3>Enterprise Security</h3>
              <p>Robust security measures protecting your most valuable digital assets</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Home;