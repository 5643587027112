import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/notfound.css";

/**
 * NotFound404 - A user-friendly 404 error page with an Oops message and navigation options.
 * @returns {React.Component} The rendered component.
 */
const NotFound404 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Page Not Found - SerityOps Innovations, LLC";
  }, []);

  return (
    <div className="notfound-container">
      <div className="notfound-content">
        <div className="notfound-icon">
          <FontAwesomeIcon icon={["fas", "file-alt"]} />
        </div>
        <h1>Oops!</h1>
        <h2>404 - Page Not Found</h2>
        <p>
          The page you are looking for might have been removed, had its name
          changed, or is temporarily unavailable.
        </p>
        <div className="notfound-actions">
          <Link to="/" className="notfound-btn primary-btn">
            <FontAwesomeIcon icon={["fas", "arrow-right"]} /> Return to Home
          </Link>
          <Link to="/get-started" className="notfound-btn secondary-btn">
            <FontAwesomeIcon icon={["fas", "envelope"]} /> Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound404;