import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/founder.css";

/**
 * Founder - Component for displaying information about the founder
 * @returns {React.Component} The rendered component.
 */
const Founder = () => {
  return (
    <div className="founder-page-container">
      <div className="founder-hero">
        <h1>About Our Founder</h1>
        <p>Meet the visionary behind SerityOps Innovations, LLC</p>
      </div>
      
      <div className="founder-card">
        <div className="founder-image">
          <div className="founder-image-placeholder">
            <FontAwesomeIcon icon={["fas", "user-circle"]} aria-hidden="true" />
          </div>
        </div>
        <div className="founder-details">
          <h2>Linn Camacho</h2>
          <p className="founder-title" style={{ color: "#0a4c9e", fontWeight: 700, textShadow: "0 1px 0 rgba(255, 255, 255, 0.8)" }}>
            Founder & CEO
          </p>
          
          <div className="founder-personal">
            <h3>Personal Story</h3>
            <p className="founder-bio">
              Born and raised in the U.S. Virgin Islands, Linn credits her mother for providing a loving 
              and nurturing home that helped her grow into the compassionate, helpful, and innovative person 
              she is today. The vibrant culture and community values of her island home instilled in her a 
              deep commitment to helping others.
            </p>
            <p className="founder-bio">
              A proud combat veteran, she served with distinction in Iraq and Afghanistan before retiring 
              from the military due to injuries sustained during combat operations. Her service experience 
              fostered a disciplined approach to overcoming challenges and a drive to create solutions that 
              make a meaningful difference.
            </p>
          </div>
          
          <div className="founder-professional">
            <h3>Professional Journey</h3>
            <p className="founder-bio">
              With over 12+ years in DevOps & Infrastructure Engineering, Linn has designed and deployed 
              secure, scalable cloud solutions across AWS environments. Her expertise in CI/CD pipelines, DevSecOps, 
              and containerized applications has resulted in significant vulnerability reductions and cost savings 
              for organizations.
            </p>
            <p className="founder-bio">
              Her technical leadership is powered by a passion for innovation and solving complex challenges. 
              This drive, combined with her military discipline and collaborative work ethic, makes her particularly 
              effective in high-impact environments where excellence is non-negotiable.
            </p>
          </div>
          
          <div className="founder-vision">
            <h3>Vision for SerityOps</h3>
            <p className="founder-bio">
              SerityOps Innovations, LLC was born from Linn's commitment to address digital challenges faced by 
              veterans and underrepresented communities. The company combines military discipline with technological 
              innovation to democratize technology access and create user-friendly, impactful solutions.
            </p>
            <p className="founder-bio">
              Her focus on outreach and helping others drives the company's mission to serve those often overlooked 
              by mainstream tech consulting, ensuring no one is left behind in the digital age.
            </p>
          </div>
          
          <div className="founder-mission">
            <h3>Looking Forward</h3>
            <blockquote className="founder-quote">
              "Technology should empower everyone—from small businesses to veterans navigating benefits. 
              My experiences from the Virgin Islands to the military have taught me that innovation must 
              serve real people with real needs. At SerityOps, we're committed to making sure no one is 
              left behind in our increasingly digital world."
              <cite>— Linn Camacho</cite>
            </blockquote>
          </div>
          
          <div className="founder-credentials">
            <div className="credential">
              <FontAwesomeIcon icon={["fas", "medal"]} aria-hidden="true" />
              <span>Combat Veteran (Iraq & Afghanistan)</span>
            </div>
            <div className="credential">
              <FontAwesomeIcon icon={["fas", "cloud"]} aria-hidden="true" />
              <span>AWS Certified Solutions Architect</span>
            </div>
            <div className="credential">
              <FontAwesomeIcon icon={["fas", "cogs"]} aria-hidden="true" />
              <span>DevOps & Infrastructure Expert</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Founder; 