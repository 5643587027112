import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/intro.css";

/**
 * Intro - The hero section component with modern animated design.
 * @returns {React.Component} The rendered component.
 */
const Intro = () => {
  return (
    <section className="intro-section">
      <div className="intro-content">
        <div className="intro-text-container">
          <h1 className="brand-heading">
            <span className="text-reveal">Transforming</span> <br />
            <span className="gradient-text">businesses</span> with <br />
            <span className="technology-text">technology</span>
          </h1>
          
          <p className="intro-text">
            Innovative solutions that drive growth, efficiency, and competitive advantage in today's digital landscape.
          </p>
          
          <div className="animated-tags">
            <div className="tag-item"><span>AI Solutions</span></div>
            <div className="tag-item"><span>Business Automation</span></div>
            <div className="tag-item"><span>Data Analytics</span></div>
            <div className="tag-item"><span>Cloud Services</span></div>
          </div>
          
          <div className="intro-cta-container">
            <a href="/services" className="intro-cta-button primary">
              Explore Solutions
              <FontAwesomeIcon icon={["fas", "arrow-right"]} className="cta-icon" />
            </a>
            <a href="/contact" className="intro-cta-button secondary">
              Get Started
            </a>
          </div>
        </div>
        
        <div className="hero-visual" aria-hidden="false">
          <div className="floating-elements">
            <a href="/services/analytics" className="element-link">
              <div className="element element-1" title="Business Analytics">
                <FontAwesomeIcon icon={["fas", "chart-line"]} />
                <span className="element-label">Analytics</span>
              </div>
            </a>
            <a href="/services/automation" className="element-link">
              <div className="element element-2" title="Automation Solutions">
                <FontAwesomeIcon icon={["fas", "cogs"]} />
                <span className="element-label">Automation</span>
              </div>
            </a>
            <a href="/services/ai" className="element-link">
              <div className="element element-3" title="AI Solutions">
                <FontAwesomeIcon icon={["fas", "brain"]} />
                <span className="element-label">AI</span>
              </div>
            </a>
            <a href="/services/cloud" className="element-link">
              <div className="element element-4" title="Cloud Infrastructure">
                <FontAwesomeIcon icon={["fas", "server"]} />
                <span className="element-label">Cloud</span>
              </div>
            </a>
            <a href="/services/security" className="element-link">
              <div className="element element-5" title="Data Security">
                <FontAwesomeIcon icon={["fas", "shield-alt"]} />
                <span className="element-label">Security</span>
              </div>
            </a>
          </div>
        </div>
      </div>
      
      <div className="section-divider intro-wave-divider">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M0,0 C151.89,51.2 334.37,99.79 554.32,84.28 C733.14,71.27 897.57,11.43 1020.42,0.05 C1058.97,-2.69 1097.53,3.45 1136.79,11.42 C1163.74,17.05 1184.58,32.32 1200,60 L1200,120 L0,120 Z" fill="#f8f9fc"></path>
        </svg>
      </div>
    </section>
  );
};

export default Intro;