import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { 
  faUser, faBuilding, faLandmark, 
  faRobot, faCloud, faMobileScreen, 
  faLightbulb, faChartLine, faLock,
  faArrowRight, faCogs, faBrain, 
  faServer, faShieldAlt, faMedal,
  faArrowUp, faEnvelope, faPhone,
  faCommentDots, faCheckCircle,
  faSpinner, faCalendarCheck,
  faFileAlt, faUserFriends, faMobileAlt,
  faPalette, faChevronDown, faSchool
} from '@fortawesome/free-solid-svg-icons';
import { 
  faLinkedin, faTwitter, faFacebook 
} from '@fortawesome/free-brands-svg-icons';

// Import Layout directly since it's required for initial render
import { Layout, ErrorBoundary } from "./components";
import "./App.css";

// Lazy load components for route-based code splitting
const Home = lazy(() => import('./components/Home'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const Terms = lazy(() => import('./components/TermsConditions'));
const ServicesPage = lazy(() => import('./components/ServicesPage'));
const GetStarted = lazy(() => import('./components/GetStarted'));
const NotFound404 = lazy(() => import('./components/NotFound404'));
const CapabilityStatement = lazy(() => import('./components/CapabilityStatement'));
const Founder = lazy(() => import('./components/Founder'));

// Add icons to library
library.add(
  faUser, faBuilding, faLandmark, 
  faRobot, faCloud, faMobileScreen, 
  faLightbulb, faChartLine, faLock,
  faArrowRight, faCogs, faBrain, 
  faServer, faShieldAlt, faMedal,
  faLinkedin, faTwitter, faFacebook,
  faArrowUp, faEnvelope, faPhone,
  faCommentDots, faCheckCircle,
  faSpinner, faCalendarCheck,
  faFileAlt, faUserFriends, faMobileAlt,
  faPalette, faChevronDown, faSchool
);

// Loading component for suspense fallback
const Loading = () => (
  <div className="loading-container">
    <div className="loading-spinner"></div>
  </div>
);

/**
 * App - The main app component responsible for routing.
 * It contains different routes for Home, Privacy Policy and a NotFound page for undefined routes.
 * Uses React.lazy and Suspense for code splitting to improve performance.
 * @returns {React.Element} the rendered element
 */
function App() {
  return (
    <Router>
      <ErrorBoundary resetOnError={false} showDetails={process.env.NODE_ENV === 'development'}>
        <Layout>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-conditions" element={<Terms />} />
              <Route path="/services" element={<ServicesPage />} />
              <Route path="/get-started" element={<GetStarted />} />
              <Route path="/capability-statement" element={<CapabilityStatement />} />
              <Route path="/founder" element={<Founder />} />
              <Route path="/error-404" element={<NotFound404 />} />
              
              {/* Catch all route - redirect to 404 */}
              <Route path="*" element={<Navigate to="/error-404" replace />} />
            </Routes>
          </Suspense>
        </Layout>
      </ErrorBoundary>
    </Router>
  );
}

export default App;