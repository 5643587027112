import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../App.css";
import "../styles/footer.css";
import logo from "../assets/images/serityops_logo.png";

/**
 * Footer - Modern footer component with links and social icons.
 * @returns {React.Component} The rendered component.
 */
const Footer = () => {
  const navigate = useNavigate();

  // Function for programmatic navigation - currently not used but kept for future implementation
  // eslint-disable-next-line no-unused-vars
  const handleNavigation = (path, e) => {
    e.preventDefault();
    console.log(`Navigating to: ${path}`);
    navigate(path);
  };

  return (
    <footer className="footer-container">
      <div className="footer-wave">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
        </svg>
      </div>
      
      <div className="footer-content">
        <div className="footer-main">
          <div className="footer-branding">
            <img src={logo} alt="SerityOps Logo" className="footer-logo" />
            <h3>SerityOps Innovations, LLC</h3>
            <p>Transforming businesses with innovative technology solutions</p>
          </div>
          
          <div className="footer-links">
            <div className="footer-links-column">
              <h4>Company</h4>
              <Link to="/founder">About Us</Link>
              <Link to="/services">Services</Link>
              <Link to="/get-started">Contact</Link>
            </div>
            
            <div className="footer-links-column">
              <h4>Services</h4>
              <Link to="/services/ai">AI Solutions</Link>
              <Link to="/services/cloud">Cloud Computing</Link>
              <Link to="/services/mobile">Mobile-First Design</Link>
            </div>
            
            <div className="footer-links-column">
              <h4>Connect</h4>
              <div className="footer-social-icons">
                <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                  <FontAwesomeIcon icon={["fab", "linkedin"]} />
                </a>
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                  <FontAwesomeIcon icon={["fab", "twitter"]} />
                </a>
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                  <FontAwesomeIcon icon={["fab", "facebook"]} />
                </a>
              </div>
            </div>
          </div>
        </div>
        
        <div className="footer-bottom">
          <div className="footer-copy">
            <p>&copy; {new Date().getFullYear()} SerityOps Innovations, LLC. All rights reserved.</p>
          </div>
          <div className="footer-legal">
            <Link to="/privacy-policy">Privacy Policy</Link>
            <span className="separator">|</span>
            <Link to="/terms-conditions">Terms & Conditions</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;