import React, { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Remove direct imports of heavy libraries
// import { jsPDF } from "jspdf";
// import html2canvas from "html2canvas";
// import { saveAs } from 'file-saver';
import CapabilityStatementPDF from "./CapabilityStatementPDF";
import "../styles/capabilitystatement.css";
import { Link } from "react-router-dom";

// Create a simple text PDF file for direct download - will be initialized lazily
let pdfBlob = null;

/**
 * CapabilityStatement - Component for displaying company capability statement for Federal and State contracts.
 * @returns {React.Component} The rendered component.
 */
const CapabilityStatement = () => {
  const pdfRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [pdfError, setPdfError] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  
  // Lazy load the PDF libraries when component mounts
  useEffect(() => {
    const loadLibraries = async () => {
      try {
        // Only load these libraries when component mounts, not on initial bundle
        // eslint-disable-next-line no-unused-vars
        const [jsPDFModule, html2canvasModule, fileSaverModule] = await Promise.all([
          import('jspdf'),
          import('html2canvas'),
          import('file-saver')
        ]);
        
        // Create the sample PDF once libraries are loaded
        const { jsPDF } = jsPDFModule;
        const pdf = new jsPDF();
        pdf.text("SerityOps Capability Statement", 20, 20);
        pdf.text("This is a sample capability statement", 20, 30);
        pdfBlob = pdf.output('blob');
      } catch (err) {
        console.error("Error loading PDF libraries:", err);
        setPdfError(true);
      }
    };
    
    loadLibraries();
  }, []);

  // Company core information
  const coreInfo = {
    businessName: "SerityOps Innovations, LLC",
    businessSize: "Micro / Small",
    ueiNumber: "L5PJSLA7PR45",
    cage: "9T3E8",
    website: "https://serityops.com",
    founded: "2024",
    certifications: [
      "Service-Disabled Veteran-Owned Small Business (SDVOSB)",
      "Woman-Owned Small Business (WOSB)",
      "Minority-Owned Business Enterprise (MBE)"
    ],
    naicsCodes: [
      { code: "541511", description: "Custom Computer Programming Services (PRIMARY)" },
      { code: "518210", description: "Data Processing, Hosting and Related Services" },
      { code: "541611", description: "Administrative Management and General Management Consulting Services" },
      { code: "541690", description: "Other Scientific and Technical Consulting Services" }
    ],
    contactInfo: {
      name: "Linn Camacho",
      title: "Founder & CEO",
      email: "info@serityops.com"
    }
  };

  // Core capabilities
  const coreCapabilities = [
    {
      title: "AI Implementations",
      icon: ["fas", "brain"],
      capabilities: [
        "Custom AI solution development tailored to specific business challenges",
        "Implementation of machine learning models for predictive analytics",
        "AI integration with existing systems and workflows",
        "Natural Language Processing (NLP) for automated communication systems",
        "Computer vision solutions for image and video analysis"
      ]
    },
    {
      title: "Website Design",
      icon: ["fas", "paintbrush"],
      capabilities: [
        "User-centered design practices for optimal user experience",
        "Responsive web design ensuring accessibility across all devices",
        "Modern interface design with focus on performance and aesthetics",
        "ADA compliance and accessibility implementation",
        "Brand integration and consistent visual identity"
      ]
    },
    {
      title: "Cloud Engineering",
      icon: ["fas", "cloud"],
      capabilities: [
        "AWS, GCP, and Azure architecture design and implementation",
        "Cloud migration strategies and execution",
        "Infrastructure as Code (IaC) for reliable deployment",
        "Cloud security implementation and compliance",
        "Cost optimization and performance tuning for cloud resources"
      ]
    },
    {
      title: "Data Analytics & Engineering",
      icon: ["fas", "chart-column"],
      capabilities: [
        "Big data architecture design and implementation",
        "Data pipeline development and optimization",
        "Business intelligence dashboard creation",
        "Data visualization for actionable insights",
        "Data strategy development and implementation"
      ]
    },
    {
      title: "Education Technology Solutions",
      icon: ["fas", "school"],
      capabilities: [
        "Student Information System (SIS) integration and customization",
        "Data interoperability between education platforms",
        "Custom education software development",
        "School district data analytics solutions",
        "Educational technology strategy consulting"
      ]
    }
  ];

  // Past performance (actual past performance)
  const pastPerformances = [
    {
      client: "Personal Celebratory Event",
      project: "Custom Event Website",
      description: "Designed and developed a personalized website for a celebratory event, featuring RSVP functionality, event information, and an interactive photo gallery."
    },
    {
      client: "Destini Delights",
      project: "Catering Order System",
      description: "Created a custom ordering web application for Destini Delights, a small catering business located in the US Virgin Islands, enabling online menu browsing, ordering, and payment processing."
    },
    {
      client: "Global Gaming Community",
      project: "Discord Bot Development",
      description: "Developed a sophisticated Discord bot with dynamic setup and translations for global gamers, featuring customizable commands and multilingual support for enhanced user experience."
    }
  ];

  // Handle navigation to Get Started page - disabled for now but may be used in future
  // eslint-disable-next-line no-unused-vars
  const handleGetStartedRedirect = (e) => {
    e.preventDefault();
    console.log("Redirecting to Get Started page");
    // Try both approaches - window.location and history API
    try {
      window.location.href = '/get-started';
    } catch (err) {
      console.error("Navigation error:", err);
      // Fallback to direct anchor
      window.open('/get-started', '_self');
    }
  };

  // Generate downloadable PDF version with comprehensive approach
  const downloadCapabilityStatement = async () => {
    if (isGenerating) return; // Prevent multiple clicks
    
    console.log("PDF download button clicked");
    setIsGenerating(true);
    
    // Show a loading toast while generating the PDF
    const loadingToast = document.createElement("div");
    loadingToast.className = "pdf-loading-toast";
    loadingToast.innerHTML = "<div class='pdf-loading-spinner'></div> Generating PDF...";
    document.body.appendChild(loadingToast);
    
    try {
      // Dynamically import the libraries if they haven't been loaded yet
      let jsPDFModule, html2canvasModule;
      
      // Load the necessary modules
      try {
        [jsPDFModule, html2canvasModule] = await Promise.all([
          import('jspdf'),
          import('html2canvas')
        ]);
      } catch (importError) {
        console.error("Error importing PDF libraries:", importError);
        throw new Error("Failed to load PDF generation libraries");
      }
      
      // Extract the necessary functions from the modules
      const { jsPDF } = jsPDFModule;
      const html2canvas = html2canvasModule.default;
      
      const pdfElement = pdfRef.current;
      
      if (!pdfElement) {
        throw new Error("PDF element not found");
      }
      
      // Clone the element for better rendering
      const pdfContainer = document.createElement('div');
      pdfContainer.style.position = 'absolute';
      pdfContainer.style.top = '0';
      pdfContainer.style.left = '0';
      pdfContainer.style.width = '8.5in';
      pdfContainer.style.height = '11in';
      pdfContainer.style.zIndex = '-9999';
      pdfContainer.style.backgroundColor = 'white';
      pdfContainer.style.opacity = '0';
      pdfContainer.style.overflow = 'hidden';
      
      // Clone the node to avoid rendering issues
      const clonedNode = pdfElement.cloneNode(true);
      pdfContainer.appendChild(clonedNode);
      document.body.appendChild(pdfContainer);
      
      // Use html2canvas with improved settings
      setTimeout(() => {
        html2canvas(clonedNode, {
          scale: 2, // Higher quality
          useCORS: true,
          logging: false,
          allowTaint: true,
          backgroundColor: 'white',
          windowWidth: 8.5 * 96, // 8.5 inches at 96 DPI
          windowHeight: 11 * 96, // 11 inches at 96 DPI
        })
        .then((canvas) => {
          try {
            const imgData = canvas.toDataURL('image/png', 1.0);
            const pdf = new jsPDF({
              orientation: 'portrait',
              unit: 'in',
              format: [8.5, 11],
              compress: true
            });
            
            // eslint-disable-next-line no-unused-vars
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save("SerityOps_Capability_Statement.pdf");
            
            // Cleanup
            document.body.removeChild(loadingToast);
            document.body.removeChild(pdfContainer);
            setIsGenerating(false);
            console.log("PDF successfully generated and saved");
          } catch (err) {
            console.error("Error creating PDF from canvas:", err);
            document.body.removeChild(loadingToast);
            document.body.removeChild(pdfContainer);
            showError("Error creating PDF. Please try the alternative download method.");
            setPdfError(true);
            setIsGenerating(false);
          }
        })
        .catch(err => {
          console.error("Error generating canvas:", err);
          document.body.removeChild(loadingToast);
          document.body.removeChild(pdfContainer);
          showError("Error generating PDF canvas. Please try the alternative download method.");
          setPdfError(true);
          setIsGenerating(false);
        });
      }, 500); // Give time for elements to render properly
    } catch (err) {
      console.error("Error in PDF generation process:", err);
      document.body.removeChild(loadingToast);
      showError("Failed to generate PDF. Please try the alternative download method.");
      setPdfError(true);
      setIsGenerating(false);
    }
  };
  
  // Show error message
  const showError = (message) => {
    const errorToast = document.createElement("div");
    errorToast.className = "pdf-error-toast";
    errorToast.innerHTML = `
      <div class="pdf-error-icon">⚠️</div>
      <div class="pdf-error-message">
        <p><strong>PDF Generation Error</strong></p>
        <p>${message}</p>
      </div>
      <button class="pdf-error-close">×</button>
    `;
    document.body.appendChild(errorToast);
    
    // Add event listener to close button
    const closeButton = errorToast.querySelector(".pdf-error-close");
    closeButton.addEventListener("click", () => {
      document.body.removeChild(errorToast);
    });
    
    // Auto-remove after 8 seconds
    setTimeout(() => {
      if (document.body.contains(errorToast)) {
        document.body.removeChild(errorToast);
      }
    }, 8000);
  };

  // A very direct download method using the file-saver package - disabled but kept for future use
  // eslint-disable-next-line no-unused-vars
  const downloadDirectPDF = async () => {
    console.log("Direct PDF download triggered");
    try {
      // Dynamically import the file-saver library
      const fileSaverModule = await import('file-saver');
      fileSaverModule.saveAs(pdfBlob, 'serityops-capability-statement.pdf');
      console.log("PDF download initiated via saveAs");
    } catch (err) {
      console.error("Error in direct download:", err);
      alert("Download failed. Please try again later.");
    }
  };

  return (
    <div className="capability-statement-container">
      {/* Hidden PDF template for generation */}
      <div style={{ position: 'absolute', left: '-9999px', top: 0 }}>
        <CapabilityStatementPDF ref={pdfRef} />
      </div>
      
      <section className="capability-hero">
        <div className="container">
          <h1>Capability Statement</h1>
          <p className="subtitle">SerityOps Innovations, LLC: Delivering Technology Excellence to Federal and State Entities</p>
          <div className="download-buttons">
            <button 
              className="download-btn" 
              onClick={downloadCapabilityStatement} 
              type="button"
              style={{ cursor: 'pointer', zIndex: 10 }}
            >
              <FontAwesomeIcon icon={["fas", "file-pdf"]} /> Download PDF
            </button>
          </div>
        </div>
      </section>

      <section className="company-profile">
        <div className="container">
          <h2>Company Profile</h2>
          <div className="profile-grid">
            <div className="profile-item">
              <h3>Business Information</h3>
              <ul className="info-list">
                <li><strong>Business Name:</strong> {coreInfo.businessName}</li>
                <li><strong>Business Size:</strong> {coreInfo.businessSize}</li>
                <li><strong>UEI Number:</strong> {coreInfo.ueiNumber}</li>
                <li><strong>CAGE Code:</strong> {coreInfo.cage}</li>
                <li><strong>Website:</strong> {coreInfo.website}</li>
                <li><strong>Year Founded:</strong> {coreInfo.founded}</li>
              </ul>
            </div>
            <div className="profile-item">
              <h3>Certifications</h3>
              <ul className="certification-list">
                {coreInfo.certifications.map((cert, index) => (
                  <li key={index}>{cert}</li>
                ))}
              </ul>
            </div>
            <div className="profile-item naics-codes">
              <h3>NAICS Codes</h3>
              <ul className="naics-list">
                {coreInfo.naicsCodes.map((naics, index) => (
                  <li key={index}>
                    <span className="naics-code">{naics.code}</span>
                    <span className="naics-description">{naics.description}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="profile-item">
              <h3>Point of Contact</h3>
              <div className="contact-info">
                <p>{coreInfo.contactInfo.name}</p>
                <p>{coreInfo.contactInfo.title}</p>
                <p>
                  <FontAwesomeIcon icon={["fas", "envelope"]} aria-hidden="true" />
                  <span>{coreInfo.contactInfo.email}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="core-capabilities">
        <div className="container">
          <h2>Core Capabilities</h2>
          <div className="capabilities-grid">
            {coreCapabilities.map((capability, index) => (
              <div className="capability-card" key={index}>
                <FontAwesomeIcon icon={capability.icon} className="capability-icon" aria-hidden="true" />
                <h3>{capability.title}</h3>
                <ul className="capability-list">
                  {capability.capabilities.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="differentiators">
        <div className="container">
          <h2>Company Differentiators</h2>
          <div className="differentiators-grid">
            <div className="differentiator">
              <FontAwesomeIcon icon={["fas", "bolt"]} className="differentiator-icon" aria-hidden="true" />
              <h3>Rapid Deployment</h3>
              <p>Our agile methodology enables quick project implementation while maintaining high quality standards.</p>
            </div>
            <div className="differentiator">
              <FontAwesomeIcon icon={["fas", "shield-halved"]} className="differentiator-icon" aria-hidden="true" />
              <h3>Security-First Approach</h3>
              <p>We integrate security at every stage of development, ensuring robust protection for all systems.</p>
            </div>
            <div className="differentiator">
              <FontAwesomeIcon icon={["fas", "handshake"]} className="differentiator-icon" aria-hidden="true" />
              <h3>Collaborative Partnership</h3>
              <p>We work closely with clients, fostering transparent communication and tailored solutions.</p>
            </div>
            <div className="differentiator">
              <FontAwesomeIcon icon={["fas", "gem"]} className="differentiator-icon" aria-hidden="true" />
              <h3>Innovation Excellence</h3>
              <p>Our team continuously explores cutting-edge technologies to deliver innovative solutions.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="past-performance">
        <div className="container">
          <h2>Past Performance</h2>
          <div className="performance-grid">
            {pastPerformances.map((performance, index) => (
              <div className="performance-card" key={index}>
                <h3>{performance.client}</h3>
                <p className="project-name">{performance.project}</p>
                <p>{performance.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="call-to-action">
        <div className="container">
          <h2>Ready to Work Together?</h2>
          <p>
            Contact us today to discuss how SerityOps Innovations, LLC can support your agency's technology needs and contribute to your mission success.
          </p>
          <Link to="/get-started" className="contact-btn">
            <FontAwesomeIcon icon={["fas", "envelope"]} /> Contact Us
          </Link>
        </div>
      </section>
    </div>
  );
};

export default CapabilityStatement;
