import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../styles/getstarted.css';

const GetStarted = () => {
  const [formData, setFormData] = useState({ 
    name: '', 
    email: '', 
    company: '',
    service: 'Select a service',
    message: '' 
  });
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Console log when component mounts to verify it's rendering
  useEffect(() => {
    console.log("GetStarted component rendered successfully");
    
    // Add a title to make sure the page shows content
    document.title = "Contact Us - SerityOps Innovations, LLC";
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    let apiUrl = 'https://3u1u0q2es1.execute-api.us-east-1.amazonaws.com/dev';

    try {
      console.log('Preparing to send data:', formData);

      await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'form_data': formData }),
      });

      console.log('Request sent. Assuming success regardless of actual outcome.');

    } catch (error) {
      console.error('Failed to submit form:', error);
    } finally {
      setIsSubmitting(false);
      setSubmitted(true);
      setFormData({ 
        name: '', 
        email: '', 
        company: '',
        service: 'Select a service',
        message: '' 
      });
    }
  };

  return (
    <div className="get-started-container">
      <div className="get-started-hero">
        <div className="get-started-hero-content">
          <h1>Let's Work Together</h1>
          <p>Discuss your project with our experts and discover how we can help you succeed</p>
        </div>
      </div>

      <div className="get-started-content">
        <div className="get-started-info">
          <div className="info-section">
            <h2>How We Can Help</h2>
            <p>SerityOps Innovations, LLC provides modern, accessible, and future-ready IT solutions to help you thrive in today's digital landscape.</p>
            
            <div className="services-list">
              <div className="service-item">
                <FontAwesomeIcon icon={["fas", "brain"]} className="service-icon" />
                <h3>AI Solutions</h3>
                <p>Custom AI implementations tailored to your business challenges</p>
              </div>
              
              <div className="service-item">
                <FontAwesomeIcon icon={["fas", "cloud"]} className="service-icon" />
                <h3>Cloud Computing</h3>
                <p>Seamless cloud migration and infrastructure management</p>
              </div>
              
              <div className="service-item">
                <FontAwesomeIcon icon={["fas", "mobile-alt"]} className="service-icon" />
                <h3>Mobile-First Design</h3>
                <p>Responsive applications that work perfectly on any device</p>
              </div>
            </div>
            
            <div className="contact-options">
              <h3>Direct Contact</h3>
              <div className="contact-option">
                <FontAwesomeIcon icon={["fas", "envelope"]} className="contact-icon" />
                <span>info@serityops.com</span>
              </div>
              <div className="social-links">
                <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                  <FontAwesomeIcon icon={["fab", "linkedin"]} />
                </a>
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                  <FontAwesomeIcon icon={["fab", "twitter"]} />
                </a>
              </div>
            </div>
          </div>
        </div>
        
        <div className="get-started-form-container">
          {!submitted ? (
            <>
              <div className="form-header">
                <FontAwesomeIcon icon={["fas", "comment-dots"]} className="form-icon" />
                <h2>Schedule a Consultation</h2>
                <p>Fill out the form below and one of our specialists will reach out to you shortly.</p>
              </div>
              
              <form className="get-started-form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Full Name <span className="required">*</span></label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="John Doe"
                    required
                  />
                </div>
                
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="email">Email Address <span className="required">*</span></label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="email@example.com"
                      required
                    />
                  </div>
                </div>
                
                <div className="form-group">
                  <label htmlFor="company">Company/Organization</label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    placeholder="Your Company"
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="service">Service of Interest <span className="required">*</span></label>
                  <select
                    id="service"
                    name="service"
                    value={formData.service}
                    onChange={handleChange}
                    required
                  >
                    <option value="Select a service" disabled>Select a service</option>
                    <option value="AI Solutions">AI Solutions</option>
                    <option value="Cloud Computing">Cloud Computing</option>
                    <option value="Mobile-First Design">Mobile-First Design</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                
                <div className="form-group">
                  <label htmlFor="message">Project Details <span className="required">*</span></label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Tell us about your project, goals, and timeline..."
                    required
                  ></textarea>
                </div>
                
                <div className="form-privacy">
                  <p>By submitting this form, you agree to our <a href="/privacy-policy">Privacy Policy</a> and <a href="/terms-conditions">Terms of Service</a>.</p>
                </div>
                
                <button type="submit" disabled={isSubmitting} className={isSubmitting ? "submitting-request" : "submit-btn"}>
                  {isSubmitting ? (
                    <>
                      <FontAwesomeIcon icon={["fas", "spinner"]} spin /> Submitting...
                    </>
                  ) : (
                    <>
                      Schedule Consultation <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                    </>
                  )}
                </button>
              </form>
            </>
          ) : (
            <div className="success-message">
              <FontAwesomeIcon icon={["fas", "check-circle"]} className="success-icon" />
              <h2>Thank You!</h2>
              <p>Your consultation request has been received. One of our specialists will contact you within 1 business day.</p>
              <div className="next-steps">
                <h3>Next Steps</h3>
                <ul>
                  <li>
                    <FontAwesomeIcon icon={["fas", "calendar-check"]} />
                    <span>Check your email for a confirmation</span>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={["fas", "file-alt"]} />
                    <span>Prepare any relevant documents or questions</span>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={["fas", "user-friends"]} />
                    <span>Our team will reach out to schedule your consultation</span>
                  </li>
                </ul>
              </div>
              <div className="additional-questions">
                <p>Have additional questions?</p>
                <a href="mailto:info@serityops.com" className="contact-email">
                  <FontAwesomeIcon icon={["fas", "envelope"]} /> Email Us
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
