import React, { useEffect, useState, useRef, memo } from "react";
import { useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/servicepage.css";

// Enhanced service offerings data to include all required services
const servicesPageDetails = [
  {
    id: "ai-implementations",
    title: 'AI Implementations',
    description: 'We deliver cutting-edge artificial intelligence solutions that transform your business processes, leveraging state-of-the-art technologies to create intelligent systems tailored to your specific needs.',
    includedServices: [
      'Custom AI model development and implementation',
      'Machine learning integration for business intelligence',
      'Natural Language Processing (NLP) for automated communication',
      'Computer vision solutions for image and video analysis',
      'AI strategy consulting and roadmap development'
    ],
    icon: ['fas', 'brain'],
    caseStudy: {
      title: 'Predictive Analytics System',
      description: "Developed a custom AI-driven predictive analytics system for a retail client that improved inventory forecasting accuracy by 37% and reduced stockouts by 42%."
    }
  },
  {
    id: "website-design",
    title: 'Website Design',
    description: 'Our approach to website design prioritizes a seamless user experience, marrying aesthetics, usability, and accessibility to distinguish your digital identity in a competitive marketplace.',
    includedServices: [
      'Responsive design ensuring optimal viewing across devices',
      'SEO optimization for maximum online visibility',
      'Brand integration that resonates with your corporate identity',
      'User-centric design for engaging user experiences',
      'Accessibility compliance to reach a wider audience'
    ],
    icon: ['fas', 'palette'],
    caseStudy: {
      title: 'E-commerce Redesign',
      description: "Redesigned an e-commerce platform resulting in a 28% increase in conversion rate and 45% decrease in cart abandonment."
    }
  },
  {
    id: "cloud-engineering",
    title: 'Cloud Engineering',
    description: 'We architect and implement robust cloud solutions across AWS, GCP, and Azure platforms, enabling scalability, security, and cost optimization for your mission-critical applications and workloads.',
    includedServices: [
      'Multi-cloud architecture design and implementation',
      'Cloud migration strategies and execution',
      'Infrastructure as Code (IaC) for consistent deployment',
      'Cloud security implementation and compliance',
      'Cost optimization and performance monitoring'
    ],
    icon: ['fas', 'cloud'],
    caseStudy: {
      title: 'Enterprise Cloud Migration',
      description: "Successfully migrated a healthcare provider's infrastructure to AWS, reducing operational costs by 32% and improving system reliability with 99.99% uptime."
    }
  },
  {
    id: "data-analytics",
    title: 'Data Analytics & Engineering',
    description: 'We transform your raw data into actionable insights through comprehensive data engineering and analytics solutions, enabling data-driven decision making across your organization.',
    includedServices: [
      'Data pipeline development and optimization',
      'Business intelligence dashboard creation',
      'Big data architecture design and implementation',
      'Data visualization for actionable insights',
      'ETL process development and management'
    ],
    icon: ['fas', 'chart-line'],
    caseStudy: {
      title: 'Financial Analytics Platform',
      description: "Built a comprehensive data analytics platform for a financial services firm that consolidated disparate data sources and reduced reporting time from days to minutes."
    }
  },
  {
    id: "education-tech",
    title: 'Education Technology Solutions',
    description: 'We specialize in developing and integrating technology solutions for educational institutions, with particular expertise in student information systems integration and data management.',
    includedServices: [
      'Student Information System (SIS) integration and customization',
      'Data interoperability between education platforms',
      'Custom education software development',
      'School district data analytics solutions',
      'Educational technology strategy consulting'
    ],
    icon: ['fas', 'school'],
    caseStudy: {
      title: 'SIS Integration Project',
      description: "Integrated multiple student information systems for a large school district, streamlining administration processes and improving data accuracy across the district."
    }
  }
];

// ValueProposition component with simplified structure
const ValueProposition = memo(() => (
  <section className="value-proposition">
    <div className="container">
      <h1>Our Services</h1>
      <div className="company-credentials">
        <span className="credential">Service-Disabled Veteran-Owned</span>
        <span className="credential">Minority-Owned</span>
        <span className="credential">Woman-Owned</span>
      </div>
      <p>
        At SerityOps Innovations, LLC, we combine cutting-edge technologies with deep industry expertise to deliver transformative solutions for businesses, consumers, and government agencies. Our focus on AI, cloud computing, and mobile-first development creates opportunities for efficiency, growth, and competitive advantage.
      </p>
    </div>
  </section>
));

// Service Card Component with simpler, more reliable expansion
const ServiceCard = memo(({ detail, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const cardRef = useRef(null);
  
  // Simplified toggle function that doesn't rely on transitions
  const toggleExpand = () => {
    const newExpandedState = !isExpanded;
    setIsExpanded(newExpandedState);
    
    // If expanding and card not in view, scroll to it after a brief delay
    if (newExpandedState && cardRef.current) {
      requestAnimationFrame(() => {
        const cardPosition = cardRef.current.getBoundingClientRect();
        if (cardPosition.top < 0) {
          window.scrollTo({
            top: window.scrollY + cardPosition.top - 20,
            behavior: 'smooth'
          });
        }
      });
    }
  };
  
  // Get icon based on service type - ensure all icons are properly defined
  const getServiceIcon = (iconArray) => {
    // If icon is properly defined, use it
    if (iconArray && iconArray.length === 2) {
      try {
        return <FontAwesomeIcon icon={iconArray} className="service-icon" size="4x" />;
      } catch (e) {
        console.error("Icon error:", e);
      }
    }
    
    // Fallback icons if the specified icon has issues
    switch(detail.id) {
      case "ai-implementations":
        return <FontAwesomeIcon icon={["fas", "brain"]} className="service-icon" size="4x" />;
      case "website-design":
        return <FontAwesomeIcon icon={["fas", "palette"]} className="service-icon" size="4x" />;
      case "cloud-engineering":
        return <FontAwesomeIcon icon={["fas", "cloud"]} className="service-icon" size="4x" />;
      case "data-analytics":
        return <FontAwesomeIcon icon={["fas", "chart-line"]} className="service-icon" size="4x" />;
      case "mobile-development":
        return <FontAwesomeIcon icon={["fas", "mobile-alt"]} className="service-icon" size="4x" />;
      default:
        return <FontAwesomeIcon icon={["fas", "cogs"]} className="service-icon" size="4x" />;
    }
  };
  
  return (
    <div 
      id={detail.id}
      ref={cardRef}
      className={`service-page-card ${isExpanded ? 'expanded' : ''}`}
    >
      <div className="icon-and-services">
        <div className="service-icon-container" aria-hidden="true">
          {getServiceIcon(detail.icon)}
        </div>
        
        <div className="service-content">
          <h2>{detail.title}</h2>
          <p>{detail.description}</p>
          
          <button 
            className="expand-toggle"
            onClick={toggleExpand}
            aria-expanded={isExpanded}
            aria-controls={`expandable-content-${detail.id}`}
          >
            {isExpanded ? 'Show less' : 'Learn more'}
            <FontAwesomeIcon 
              icon={["fas", "chevron-down"]} 
              className={`expand-icon ${isExpanded ? 'rotate' : ''}`}
              aria-hidden="true"
            />
          </button>
          
          {isExpanded && (
            <div 
              id={`expandable-content-${detail.id}`}
              className="expanded-content"
            >
              <h3>Our {detail.title} Services Include:</h3>
              <ul className="services-list">
                {detail.includedServices.map((service, idx) => (
                  <li key={idx}>{service}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

// Capability Statement Promo Component
const CapabilityStatementPromo = memo(() => (
  <section className="capability-statement-promo">
    <div className="container">
      <div className="promo-content">
        <div className="promo-text">
          <h2>Federal & State Contractors</h2>
          <p>
            As a Service-Disabled Veteran-Owned Small Business (SDVOSB), we're uniquely positioned to serve federal and state agencies with our full range of technology services.
          </p>
          <p>
            View our detailed capability statement to learn about our NAICS codes, past performance, and core differentiators.
          </p>
        </div>
        <div className="promo-actions">
          <Link to="/capability-statement" className="btn btn-primary">
            View Capability Statement
          </Link>
        </div>
      </div>
    </div>
  </section>
));

// Services Page Component with simplified animation handling
const ServicesPage = () => {
  const location = useLocation();
  
  useEffect(() => {
    // Handle hash navigation
    if (location.hash) {
      setTimeout(() => {
        try {
          const id = location.hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        } catch (error) {
          console.error("Hash navigation error:", error);
        }
      }, 100);
    }
  }, [location]);
  
  return (
    <>
      <ValueProposition />
      <section id="service-page-details">
        <div className="container">
          {servicesPageDetails.map((detail, index) => (
            <ServiceCard key={detail.id} detail={detail} index={index} />
          ))}
        </div>
      </section>
      <CapabilityStatementPromo />
    </>
  );
};

export default ServicesPage;
